.verification-methods{
    .verification-method{
        border:2px solid whitesmoke;
        &.activated{
            border-color: #2835ff;
            .title{
                font-weight: 500;
            }
        }
        border-radius: 10px;
        padding: 10px;
        margin: 0;
        .symbol-img{
            height: 24px;
        }
        .btn{
            padding-left: 9px !important;
            padding-right: 9px !important;
        }
    }
}