.press-release-table{
    .c-logo{
        width: 90px;
        height: 60px;
        background: url() no-repeat 50%;
        background-size: contain;
        background-position: center center;
        background-size: cover;
        border-radius: 15px;
        flex-shrink: 0;
    }
    .rt-td{
        color: #181c32 !important;
        font-weight: 600 !important;
    }
    .rt-resizable-header{
        max-width: 450px;
    }
}