.text-right {
  text-align: right;
}
.avolon-line {
  width: 60%;
  height: 4px !important;
  opacity: 1 !important;
  border-width: 0;
  background: #37db70;
  margin: 10px 0px;
}

.text-editor-wrapper {
  border: 1px solid #e2e5ec;
  border-radius: 5px;
}

.text-editor {
  padding-left: 10px;
  padding-right: 10px;
  min-height: 200px;
}

.knowledgebase-toolbar {
  border: 0px solid #e2e5ec;
  border-bottom-width: 1px;
  border-radius: 0px;
}

/* Start TABLE Styles */
.ReactTable {
  border: none;
}

.Table__pagination .switchPageButton:hover,
.Table__pageButton:hover,
.Table__pageButton--active {
  background: #3699ff;
}

.rt-td {
  color: #6c7293;
  padding: 0;
}

.rt-tr-group:hover {
  background-color: #fafbfc;
}

.-sort-desc,
.-sort-asc {
  box-shadow: none !important;
}

.rt-thead .rt-resizable-header-content {
  color: #a1a5b7 !important;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
}

.rt-thead .-sort-asc .rt-resizable-header-content,
.rt-thead .-sort-desc .rt-resizable-header-content {
  color: #2835ff !important;
}

.rt-thead .rt-th {
  padding: 0.75rem 1rem !important;
}

.-sort-asc .rt-resizer::before {
  content: "\2191";
  color: #2835ff;
  vertical-align: middle;
  font-weight: 600;
}

.-sort-desc .rt-resizer::after {
  content: "\2193";
  color: #2835ff;
  vertical-align: middle;
  font-weight: 600;
}

.ReactTable .rt-resizer {
  top: 5px;
}

.ReactTable .rt-td {
  align-self: center;
}

.Table__page .select-page:hover {
  background-color: #2835ff;
}

.rt-resizable-header-content {
  color: #595d6e !important;
  font-weight: 500;
}

.ReactTable .rt-expander:after {
  border-top: 7px solid #35db6f !important;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  border: 0 !important;
}

.ReactTable .rt-thead.-header {
  padding-bottom: 0 !important;
}

.ReactTable .rt-tr {
  padding-top: unset !important;
  padding-bottom: unset !important;
  overflow-x: scroll;
}

.ReactTable .rt-tbody .rt-td {
  border-right: unset !important;
}

.ReactTable .switchPageButton,
.ReactTable .Table__pageButton {
  border-radius: 50% !important;
  font-size: unset !important;
}

.ReactTable .rt-tbody {
  min-height: 50px;
}

.ReactTable .rt-noData {
  top: 90px !important;
}

/* End TABLE Styles */

.flatpickr-wrapper {
  width: 100%;
}

/*** Styles ***/

.m-1 {
  margin: 0.25rem !important;
}

.text-grey {
  color: #80808f;
}

/*** Added Styles ***/

.font-size-h1 {
  font-size: 2rem !important;
}

.font-size-h2 {
  font-size: 1.75rem !important;
}

.font-size-h3 {
  font-size: 1.5rem !important;
}

.font-size-h4 {
  font-size: 1.35rem !important;
}

.font-size-h5 {
  font-size: 1.25rem !important;
}

.font-size-h6 {
  font-size: 1.175rem !important;
}

.pull-right {
  float: right;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.mt-3,
.my-3 {
  margin-top: 0.75rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 0.75rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 0.75rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.mt-5,
.my-5 {
  margin-top: 1.25rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 1.25rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 1.25rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 1.25rem !important;
}


.m-6 {
  margin: 1.5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 1.5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 1.5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 1.5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 1.5rem !important;
}

.m-7 {
  margin: 1.75rem !important;
}

.mt-7,
.my-7 {
  margin-top: 1.75rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 1.75rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 1.75rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 1.75rem !important;
}

.m-8 {
  margin: 2rem !important;
}

.mt-8,
.my-8 {
  margin-top: 2rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 2rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 2rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 2rem !important;
}

.m-9 {
  margin: 2.25rem !important;
}

.mt-9,
.my-9 {
  margin-top: 2.25rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 2.25rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 2.25rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 2.25rem !important;
}

.m-10 {
  margin: 2.5rem !important;
}

.mt-10,
.my-10 {
  margin-top: 2.5rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 2.5rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 2.5rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 2.5rem !important;
}

.m-11 {
  margin: 2.75rem !important;
}

.mt-11,
.my-11 {
  margin-top: 2.75rem !important;
}

.mr-11,
.mx-11 {
  margin-right: 2.75rem !important;
}

.mb-11,
.my-11 {
  margin-bottom: 2.75rem !important;
}

.ml-11,
.mx-11 {
  margin-left: 2.75rem !important;
}

.m-12 {
  margin: 3rem !important;
}

.mt-12,
.my-12 {
  margin-top: 3rem !important;
}

.mr-12,
.mx-12 {
  margin-right: 3rem !important;
}

.mb-12,
.my-12 {
  margin-bottom: 3rem !important;
}

.ml-12,
.mx-12 {
  margin-left: 3rem !important;
}

.m-13 {
  margin: 3.25rem !important;
}

.mt-13,
.my-13 {
  margin-top: 3.25rem !important;
}

.mr-13,
.mx-13 {
  margin-right: 3.25rem !important;
}

.mb-13,
.my-13 {
  margin-bottom: 3.25rem !important;
}

.ml-13,
.mx-13 {
  margin-left: 3.25rem !important;
}

.m-14 {
  margin: 3.5rem !important;
}

.mt-14,
.my-14 {
  margin-top: 3.5rem !important;
}

.mr-14,
.mx-14 {
  margin-right: 3.5rem !important;
}

.mb-14,
.my-14 {
  margin-bottom: 3.5rem !important;
}

.ml-14,
.mx-14 {
  margin-left: 3.5rem !important;
}

.m-15 {
  margin: 3.75rem !important;
}

.mt-15,
.my-15 {
  margin-top: 3.75rem !important;
}

.mr-15,
.mx-15 {
  margin-right: 3.75rem !important;
}

.mb-15,
.my-15 {
  margin-bottom: 3.75rem !important;
}

.ml-15,
.mx-15 {
  margin-left: 3.75rem !important;
}

.m-16 {
  margin: 4rem !important;
}

.mt-16,
.my-16 {
  margin-top: 4rem !important;
}

.mr-16,
.mx-16 {
  margin-right: 4rem !important;
}

.mb-16,
.my-16 {
  margin-bottom: 4rem !important;
}

.ml-16,
.mx-16 {
  margin-left: 4rem !important;
}

.m-17 {
  margin: 4.25rem !important;
}

.mt-17,
.my-17 {
  margin-top: 4.25rem !important;
}

.mr-17,
.mx-17 {
  margin-right: 4.25rem !important;
}

.mb-17,
.my-17 {
  margin-bottom: 4.25rem !important;
}

.ml-17,
.mx-17 {
  margin-left: 4.25rem !important;
}

.btn.btn-outline-dark.focus:not(.btn-text),
.btn.btn-outline-dark:focus:not(.btn-text),
.btn.btn-outline-dark:hover:not(.btn-text):not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #212121;
  border-color: #212121;
}

.btn,
.btn.disabled,
.btn.disabled i,
.btn.focus,
.btn.focus i,
.btn:disabled,
.btn:disabled i,
.btn:focus:not(.btn-text),
.btn:focus:not(.btn-text) i,
.btn:hover:not(.btn-text),
.btn:hover:not(.btn-text) i,
.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled).active i,
.btn:not(:disabled):not(.disabled):active:not(.btn-text),
.btn:not(:disabled):not(.disabled):active:not(.btn-text) i,
.btn i,
.show > .btn.dropdown-toggle,
.show > .btn.dropdown-toggle i {
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
}

.btn-outline-dark {
  color: #212121;
  border-color: #212121 !important;
}
.btn-outline-dark:hover {
  color: #ffffff;
  background-color: #212121;
  border-color: #212121;
}
.btn-outline-dark:focus,
.btn-outline-dark.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(33, 33, 33, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(33, 33, 33, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #212121;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #212121;
  border-color: #212121;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(33, 33, 33, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(33, 33, 33, 0.5);
}

.primary-color {
  color: #35db6f !important;
}

.primary-color:hover,
.danger-color:hover {
  color: white !important;
}

.font-weight-bold {
  font-weight: 500 !important;
}

.font-weight-bolder {
  font-weight: 600 !important;
}

.alert.alert-primary {
  background-color: #3699ff;
  border-color: #3699ff;
  color: #ffffff;
}
.alert.alert-primary .close i {
  opacity: 0.8;
  color: #ffffff;
}
.alert.alert-primary .close:focus i,
.alert.alert-primary .close:hover i {
  opacity: 1;
  color: #ffffff;
}

.alert.alert-secondary {
  background-color: #e5eaee;
  border-color: #e5eaee;
  color: #464e5f;
}
.alert.alert-secondary .close i {
  opacity: 0.8;
  color: #464e5f;
}
.alert.alert-secondary .close:focus i,
.alert.alert-secondary .close:hover i {
  opacity: 1;
  color: #464e5f;
}

.alert.alert-success {
  background-color: #1bc5bd;
  border-color: #1bc5bd;
  color: #ffffff;
}
.alert.alert-success .close i {
  opacity: 0.8;
  color: #ffffff;
}
.alert.alert-success .close:focus i,
.alert.alert-success .close:hover i {
  opacity: 1;
  color: #ffffff;
}

.alert.alert-info {
  background-color: #8950fc;
  border-color: #8950fc;
  color: #ffffff;
}
.alert.alert-info .close i {
  opacity: 0.8;
  color: #ffffff;
}
.alert.alert-info .close:focus i,
.alert.alert-info .close:hover i {
  opacity: 1;
  color: #ffffff;
}

.alert.alert-warning {
  background-color: #ffa800;
  border-color: #ffa800;
  color: #ffffff;
}
.alert.alert-warning .close i {
  opacity: 0.8;
  color: #ffffff;
}
.alert.alert-warning .close:focus i,
.alert.alert-warning .close:hover i {
  opacity: 1;
  color: #ffffff;
}

.alert.alert-danger {
  background-color: #f64e60;
  border-color: #f64e60;
  color: #ffffff;
}
.alert.alert-danger .close i {
  opacity: 0.8;
  color: #ffffff;
}
.alert.alert-danger .close:focus i,
.alert.alert-danger .close:hover i {
  opacity: 1;
  color: #ffffff;
}

.alert.alert-light {
  background-color: #f3f6f9;
  border-color: #f3f6f9;
  color: #80808f;
}
.alert.alert-light .close i {
  opacity: 0.8;
  color: #80808f;
}
.alert.alert-light .close:focus i,
.alert.alert-light .close:hover i {
  opacity: 1;
  color: #80808f;
}

.alert.alert-dark {
  background-color: #212121;
  border-color: #212121;
  color: #ffffff;
}
.alert.alert-dark .close i {
  opacity: 0.8;
  color: #ffffff;
}
.alert.alert-dark .close:focus i,
.alert.alert-dark .close:hover i {
  opacity: 1;
  color: #ffffff;
}

.alert.alert-white {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #464e5f;
}
.alert.alert-white .close i {
  opacity: 0.8;
  color: #464e5f;
}
.alert.alert-white .close:focus i,
.alert.alert-white .close:hover i {
  opacity: 1;
  color: #464e5f;
}

.alert.alert-dark-75 {
  background-color: #464e5f;
  border-color: #464e5f;
  color: #ffffff;
}
.alert.alert-dark-75 .close i {
  opacity: 0.8;
  color: #ffffff;
}
.alert.alert-dark-75 .close:focus i,
.alert.alert-dark-75 .close:hover i {
  opacity: 1;
  color: #ffffff;
}

.alert.alert-custom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  padding: 1.5rem 2rem;
}

.alert.alert-custom .alert-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1.25rem 0 0;
}

.alert.alert-custom .alert-icon i {
  font-size: 2.4rem;
}

.alert.alert-custom .alert-icon.alert-icon-top {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}
.alert.alert-custom .alert-text {
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.alert.alert-custom .alert-close {
  padding: 0 0 0 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  line-height: 0;
}
.alert.alert-custom .alert-close .close {
  text-shadow: none !important;
  cursor: pointer;
  line-height: 0;
}
.alert.alert-custom .alert-close i {
  -webkit-transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  font-size: 1rem;
}
.alert.alert-custom .alert-close i.ki {
  font-size: 0.75rem;
}
.alert.alert-custom .alert-close:focus i,
.alert.alert-custom .alert-close:hover i {
  -webkit-transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
}
.alert.alert-custom.alert-outline-2x {
  border-width: 2px;
}
.alert.alert-custom.alert-white {
  background-color: #ffffff;
  border-color: #ffffff;
}
.alert.alert-custom.alert-white .alert-icon i {
  color: #464e5f;
}
.alert.alert-custom.alert-white .alert-text {
  color: #464e5f;
}
.alert.alert-custom.alert-white .alert-close i {
  color: #464e5f;
}
.alert.alert-custom.alert-default {
  background-color: #f3f6f9;
  border-color: #f3f6f9;
}
.alert.alert-custom.alert-shadow {
  -webkit-box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
}
.alert.alert-custom.alert-primary {
  background-color: #3699ff;
  border-color: #3699ff;
}
.alert.alert-custom.alert-primary .alert-icon i {
  opacity: 0.8;
  color: #ffffff;
}
.alert.alert-custom.alert-primary .alert-text {
  color: #ffffff;
}
.alert.alert-custom.alert-primary .alert-close i {
  color: #ffffff;
}
.alert.alert-custom.alert-primary .alert-close:focus,
.alert.alert-custom.alert-primary .alert-close:hover {
  opacity: 1;
  color: #ffffff;
}
.alert.alert-custom.alert-light-primary {
  background-color: #e1f0ff;
  border-color: transparent;
}
.alert.alert-custom.alert-light-primary .alert-icon i {
  color: #3699ff;
}
.alert.alert-custom.alert-light-primary .alert-text {
  color: #3699ff;
}
.alert.alert-custom.alert-light-primary .alert-close i {
  color: #3699ff;
}
.alert.alert-custom.alert-light-primary .alert-close:focus,
.alert.alert-custom.alert-light-primary .alert-close:hover {
  color: #187de4;
}
.alert.alert-custom.alert-light-primary.alert-notice {
  border: 0;
  border-left: 4px solid #3699ff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.alert.alert-custom.alert-outline-primary {
  background-color: transparent;
  border-color: #3699ff;
}
.alert.alert-custom.alert-outline-primary .alert-icon i {
  color: #3699ff;
}
.alert.alert-custom.alert-outline-primary .alert-text {
  color: #3699ff;
}
.alert.alert-custom.alert-outline-primary .alert-close i {
  opacity: 0.8;
  color: #3699ff;
}
.alert.alert-custom.alert-outline-primary .alert-close:focus i,
.alert.alert-custom.alert-outline-primary .alert-close:hover i {
  opacity: 1;
}
.alert.alert-custom.alert-secondary {
  background-color: #e5eaee;
  border-color: #e5eaee;
}
.alert.alert-custom.alert-secondary .alert-icon i {
  opacity: 0.8;
  color: #464e5f;
}
.alert.alert-custom.alert-secondary .alert-text {
  color: #464e5f;
}
.alert.alert-custom.alert-secondary .alert-close i {
  color: #464e5f;
}
.alert.alert-custom.alert-secondary .alert-close:focus,
.alert.alert-custom.alert-secondary .alert-close:hover {
  opacity: 1;
  color: #464e5f;
}
.alert.alert-custom.alert-light-secondary {
  background-color: #ecf0f3;
  border-color: transparent;
}
.alert.alert-custom.alert-light-secondary .alert-icon i {
  color: #e5eaee;
}
.alert.alert-custom.alert-light-secondary .alert-text {
  color: #e5eaee;
}
.alert.alert-custom.alert-light-secondary .alert-close i {
  color: #e5eaee;
}
.alert.alert-custom.alert-light-secondary .alert-close:focus,
.alert.alert-custom.alert-light-secondary .alert-close:hover {
  color: #d6d6e0;
}
.alert.alert-custom.alert-light-secondary.alert-notice {
  border: 0;
  border-left: 4px solid #e5eaee;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.alert.alert-custom.alert-outline-secondary {
  background-color: transparent;
  border-color: #e5eaee;
}
.alert.alert-custom.alert-outline-secondary .alert-icon i {
  color: #464e5f;
}
.alert.alert-custom.alert-outline-secondary .alert-text {
  color: #464e5f;
}
.alert.alert-custom.alert-outline-secondary .alert-close i {
  opacity: 0.8;
  color: #464e5f;
}
.alert.alert-custom.alert-outline-secondary .alert-close:focus i,
.alert.alert-custom.alert-outline-secondary .alert-close:hover i {
  opacity: 1;
}
.alert.alert-custom.alert-success {
  background-color: #1bc5bd;
  border-color: #1bc5bd;
}
.alert.alert-custom.alert-success .alert-icon i {
  opacity: 0.8;
  color: #ffffff;
}
.alert.alert-custom.alert-success .alert-text {
  color: #ffffff;
}
.alert.alert-custom.alert-success .alert-close i {
  color: #ffffff;
}
.alert.alert-custom.alert-success .alert-close:focus,
.alert.alert-custom.alert-success .alert-close:hover {
  opacity: 1;
  color: #ffffff;
}
.alert.alert-custom.alert-light-success {
  background-color: #c9f7f5;
  border-color: transparent;
}
.alert.alert-custom.alert-light-success .alert-icon i {
  color: #1bc5bd;
}
.alert.alert-custom.alert-light-success .alert-text {
  color: #1bc5bd;
}
.alert.alert-custom.alert-light-success .alert-close i {
  color: #1bc5bd;
}
.alert.alert-custom.alert-light-success .alert-close:focus,
.alert.alert-custom.alert-light-success .alert-close:hover {
  color: #0bb7af;
}
.alert.alert-custom.alert-light-success.alert-notice {
  border: 0;
  border-left: 4px solid #1bc5bd;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.alert.alert-custom.alert-outline-success {
  background-color: transparent;
  border-color: #1bc5bd;
}
.alert.alert-custom.alert-outline-success .alert-icon i {
  color: #1bc5bd;
}
.alert.alert-custom.alert-outline-success .alert-text {
  color: #1bc5bd;
}
.alert.alert-custom.alert-outline-success .alert-close i {
  opacity: 0.8;
  color: #1bc5bd;
}
.alert.alert-custom.alert-outline-success .alert-close:focus i,
.alert.alert-custom.alert-outline-success .alert-close:hover i {
  opacity: 1;
}
.alert.alert-custom.alert-info {
  background-color: #8950fc;
  border-color: #8950fc;
}
.alert.alert-custom.alert-info .alert-icon i {
  opacity: 0.8;
  color: #ffffff;
}
.alert.alert-custom.alert-info .alert-text {
  color: #ffffff;
}
.alert.alert-custom.alert-info .alert-close i {
  color: #ffffff;
}
.alert.alert-custom.alert-info .alert-close:focus,
.alert.alert-custom.alert-info .alert-close:hover {
  opacity: 1;
  color: #ffffff;
}
.alert.alert-custom.alert-light-info {
  background-color: #eee5ff;
  border-color: transparent;
}
.alert.alert-custom.alert-light-info .alert-icon i {
  color: #8950fc;
}
.alert.alert-custom.alert-light-info .alert-text {
  color: #8950fc;
}
.alert.alert-custom.alert-light-info .alert-close i {
  color: #8950fc;
}
.alert.alert-custom.alert-light-info .alert-close:focus,
.alert.alert-custom.alert-light-info .alert-close:hover {
  color: #7337ee;
}
.alert.alert-custom.alert-light-info.alert-notice {
  border: 0;
  border-left: 4px solid #8950fc;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.alert.alert-custom.alert-outline-info {
  background-color: transparent;
  border-color: #8950fc;
}
.alert.alert-custom.alert-outline-info .alert-icon i {
  color: #8950fc;
}
.alert.alert-custom.alert-outline-info .alert-text {
  color: #8950fc;
}
.alert.alert-custom.alert-outline-info .alert-close i {
  opacity: 0.8;
  color: #8950fc;
}
.alert.alert-custom.alert-outline-info .alert-close:focus i,
.alert.alert-custom.alert-outline-info .alert-close:hover i {
  opacity: 1;
}
.alert.alert-custom.alert-warning {
  background-color: #ffa800;
  border-color: #ffa800;
}
.alert.alert-custom.alert-warning .alert-icon i {
  opacity: 0.8;
  color: #ffffff;
}
.alert.alert-custom.alert-warning .alert-text {
  color: #ffffff;
}
.alert.alert-custom.alert-warning .alert-close i {
  color: #ffffff;
}
.alert.alert-custom.alert-warning .alert-close:focus,
.alert.alert-custom.alert-warning .alert-close:hover {
  opacity: 1;
  color: #ffffff;
}
.alert.alert-custom.alert-light-warning {
  background-color: #fff4de;
  border-color: transparent;
}
.alert.alert-custom.alert-light-warning .alert-icon i {
  color: #ffa800;
}
.alert.alert-custom.alert-light-warning .alert-text {
  color: #ffa800;
}
.alert.alert-custom.alert-light-warning .alert-close i {
  color: #ffa800;
}
.alert.alert-custom.alert-light-warning .alert-close:focus,
.alert.alert-custom.alert-light-warning .alert-close:hover {
  color: #ee9d01;
}
.alert.alert-custom.alert-light-warning.alert-notice {
  border: 0;
  border-left: 4px solid #ffa800;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.alert.alert-custom.alert-outline-warning {
  background-color: transparent;
  border-color: #ffa800;
}
.alert.alert-custom.alert-outline-warning .alert-icon i {
  color: #ffa800;
}
.alert.alert-custom.alert-outline-warning .alert-text {
  color: #ffa800;
}
.alert.alert-custom.alert-outline-warning .alert-close i {
  opacity: 0.8;
  color: #ffa800;
}
.alert.alert-custom.alert-outline-warning .alert-close:focus i,
.alert.alert-custom.alert-outline-warning .alert-close:hover i {
  opacity: 1;
}
.alert.alert-custom.alert-danger {
  background-color: #f64e60;
  border-color: #f64e60;
}
.alert.alert-custom.alert-danger .alert-icon i {
  opacity: 0.8;
  color: #ffffff;
}
.alert.alert-custom.alert-danger .alert-text {
  color: #ffffff;
}
.alert.alert-custom.alert-danger .alert-close i {
  color: #ffffff;
}
.alert.alert-custom.alert-danger .alert-close:focus,
.alert.alert-custom.alert-danger .alert-close:hover {
  opacity: 1;
  color: #ffffff;
}
.alert.alert-custom.alert-light-danger {
  background-color: #ffe2e5;
  border-color: transparent;
}
.alert.alert-custom.alert-light-danger .alert-icon i {
  color: #f64e60;
}
.alert.alert-custom.alert-light-danger .alert-text {
  color: #f64e60;
}
.alert.alert-custom.alert-light-danger .alert-close i {
  color: #f64e60;
}
.alert.alert-custom.alert-light-danger .alert-close:focus,
.alert.alert-custom.alert-light-danger .alert-close:hover {
  color: #ee2d41;
}
.alert.alert-custom.alert-light-danger.alert-notice {
  border: 0;
  border-left: 4px solid #f64e60;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.alert.alert-custom.alert-outline-danger {
  background-color: transparent;
  border-color: #f64e60;
}
.alert.alert-custom.alert-outline-danger .alert-icon i {
  color: #f64e60;
}
.alert.alert-custom.alert-outline-danger .alert-text {
  color: #f64e60;
}
.alert.alert-custom.alert-outline-danger .alert-close i {
  opacity: 0.8;
  color: #f64e60;
}
.alert.alert-custom.alert-outline-danger .alert-close:focus i,
.alert.alert-custom.alert-outline-danger .alert-close:hover i {
  opacity: 1;
}
.alert.alert-custom.alert-light {
  background-color: #f3f6f9;
  border-color: #f3f6f9;
}
.alert.alert-custom.alert-light .alert-icon i {
  opacity: 0.8;
  color: #80808f;
}
.alert.alert-custom.alert-light .alert-text {
  color: #80808f;
}
.alert.alert-custom.alert-light .alert-close i {
  color: #80808f;
}
.alert.alert-custom.alert-light .alert-close:focus,
.alert.alert-custom.alert-light .alert-close:hover {
  opacity: 1;
  color: #80808f;
}
.alert.alert-custom.alert-light-light {
  background-color: #f3f6f9;
  border-color: transparent;
}
.alert.alert-custom.alert-light-light .alert-icon i {
  color: #f3f6f9;
}
.alert.alert-custom.alert-light-light .alert-text {
  color: #f3f6f9;
}
.alert.alert-custom.alert-light-light .alert-close i {
  color: #f3f6f9;
}
.alert.alert-custom.alert-light-light .alert-close:focus,
.alert.alert-custom.alert-light-light .alert-close:hover {
  color: #e5eaee;
}
.alert.alert-custom.alert-light-light.alert-notice {
  border: 0;
  border-left: 4px solid #f3f6f9;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.alert.alert-custom.alert-outline-light {
  background-color: transparent;
  border-color: #f3f6f9;
}
.alert.alert-custom.alert-outline-light .alert-icon i {
  color: #464e5f;
}
.alert.alert-custom.alert-outline-light .alert-text {
  color: #464e5f;
}
.alert.alert-custom.alert-outline-light .alert-close i {
  opacity: 0.8;
  color: #464e5f;
}
.alert.alert-custom.alert-outline-light .alert-close:focus i,
.alert.alert-custom.alert-outline-light .alert-close:hover i {
  opacity: 1;
}
.alert.alert-custom.alert-dark {
  background-color: #212121;
  border-color: #212121;
}
.alert.alert-custom.alert-dark .alert-icon i {
  opacity: 0.8;
  color: #ffffff;
}
.alert.alert-custom.alert-dark .alert-text {
  color: #ffffff;
}
.alert.alert-custom.alert-dark .alert-close i {
  color: #ffffff;
}
.alert.alert-custom.alert-dark .alert-close:focus,
.alert.alert-custom.alert-dark .alert-close:hover {
  opacity: 1;
  color: #ffffff;
}
.alert.alert-custom.alert-light-dark {
  background-color: #d6d6e0;
  border-color: transparent;
}
.alert.alert-custom.alert-light-dark .alert-icon i {
  color: #212121;
}
.alert.alert-custom.alert-light-dark .alert-text {
  color: #212121;
}
.alert.alert-custom.alert-light-dark .alert-close i {
  color: #212121;
}
.alert.alert-custom.alert-light-dark .alert-close:focus,
.alert.alert-custom.alert-light-dark .alert-close:hover {
  color: #111111;
}
.alert.alert-custom.alert-light-dark.alert-notice {
  border: 0;
  border-left: 4px solid #212121;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.alert.alert-custom.alert-outline-dark {
  background-color: transparent;
  border-color: #212121;
}
.alert.alert-custom.alert-outline-dark .alert-icon i {
  color: #212121;
}
.alert.alert-custom.alert-outline-dark .alert-text {
  color: #212121;
}
.alert.alert-custom.alert-outline-dark .alert-close i {
  opacity: 0.8;
  color: #212121;
}
.alert.alert-custom.alert-outline-dark .alert-close:focus i,
.alert.alert-custom.alert-outline-dark .alert-close:hover i {
  opacity: 1;
}
.alert.alert-custom.alert-white {
  background-color: #ffffff;
  border-color: #ffffff;
}
.alert.alert-custom.alert-white .alert-icon i {
  opacity: 0.8;
  color: #464e5f;
}
.alert.alert-custom.alert-white .alert-text {
  color: #464e5f;
}
.alert.alert-custom.alert-white .alert-close i {
  color: #464e5f;
}
.alert.alert-custom.alert-white .alert-close:focus,
.alert.alert-custom.alert-white .alert-close:hover {
  opacity: 1;
  color: #464e5f;
}
.alert.alert-custom.alert-light-white {
  background-color: #ffffff;
  border-color: transparent;
}
.alert.alert-custom.alert-light-white .alert-icon i {
  color: #ffffff;
}
.alert.alert-custom.alert-light-white .alert-text {
  color: #ffffff;
}
.alert.alert-custom.alert-light-white .alert-close i {
  color: #ffffff;
}
.alert.alert-custom.alert-light-white .alert-close:focus,
.alert.alert-custom.alert-light-white .alert-close:hover {
  color: #f3f6f9;
}
.alert.alert-custom.alert-light-white.alert-notice {
  border: 0;
  border-left: 4px solid #ffffff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.alert.alert-custom.alert-outline-white {
  background-color: transparent;
  border-color: #ffffff;
}
.alert.alert-custom.alert-outline-white .alert-icon i {
  color: #ffffff;
}
.alert.alert-custom.alert-outline-white .alert-text {
  color: #ffffff;
}
.alert.alert-custom.alert-outline-white .alert-close i {
  opacity: 0.8;
  color: #ffffff;
}
.alert.alert-custom.alert-outline-white .alert-close:focus i,
.alert.alert-custom.alert-outline-white .alert-close:hover i {
  opacity: 1;
}
.alert.alert-custom.alert-dark-75 {
  background-color: #464e5f;
  border-color: #464e5f;
}
.alert.alert-custom.alert-dark-75 .alert-icon i {
  opacity: 0.8;
  color: #ffffff;
}
.alert.alert-custom.alert-dark-75 .alert-text {
  color: #ffffff;
}
.alert.alert-custom.alert-dark-75 .alert-close i {
  color: #ffffff;
}
.alert.alert-custom.alert-dark-75 .alert-close:focus,
.alert.alert-custom.alert-dark-75 .alert-close:hover {
  opacity: 1;
  color: #ffffff;
}
.alert.alert-custom.alert-light-dark-75 {
  background-color: #e5eaee;
  border-color: transparent;
}
.alert.alert-custom.alert-light-dark-75 .alert-icon i {
  color: #464e5f;
}
.alert.alert-custom.alert-light-dark-75 .alert-text {
  color: #464e5f;
}
.alert.alert-custom.alert-light-dark-75 .alert-close i {
  color: #464e5f;
}
.alert.alert-custom.alert-light-dark-75 .alert-close:focus,
.alert.alert-custom.alert-light-dark-75 .alert-close:hover {
  color: #212121;
}
.alert.alert-custom.alert-light-dark-75.alert-notice {
  border: 0;
  border-left: 4px solid #464e5f;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.alert.alert-custom.alert-outline-dark-75 {
  background-color: transparent;
  border-color: #464e5f;
}
.alert.alert-custom.alert-outline-dark-75 .alert-icon i {
  color: #464e5f;
}
.alert.alert-custom.alert-outline-dark-75 .alert-text {
  color: #464e5f;
}
.alert.alert-custom.alert-outline-dark-75 .alert-close i {
  opacity: 0.8;
  color: #464e5f;
}
.alert.alert-custom.alert-outline-dark-75 .alert-close:focus i,
.alert.alert-custom.alert-outline-dark-75 .alert-close:hover i {
  opacity: 1;
}

.alert-button button {
  margin-top: 8px;
  background: transparent;
  color: white;
  border: 1px solid white;
  padding: 6px 20px;
}

.alert-button button:hover {
  background-color: white;
  color: #ffb822;
}

.flaticon-warning-sign:before {
  content: "\f145";
}

.flaticon-warning:before {
  content: "\f16f";
}

.light-border {
  border-right: 1px solid whitesmoke;
}

.display-flex {
  display: flex
}

.w--100 {
  width: 100% !important;
}

.w--50 {
  width: 50% !important;
}

.text-span {
  color: #2835ff;
}

.text-span:hover {
  color: #35db6f;
}

.phone-input-styles {
  background-color: transparent !important;
  border-color: transparent !important;
} 


@media (min-width: 992px) {
  .font-size-h1-lg {
    font-size: 2rem !important;
  }
}
.custom-alert-danger{
  background-color: #ffe2e5;
  border-color: #ffe2e5;
  color: #f76171;
}
.jodit-add-new-line span{
  display: none !important;
}
.full-width-dropzone {
  display: flex;
  width: 100%;
  padding: 0 !important;
}

.full-width-dropzone .drop-label {
  flex-grow: 1 !important;
  width: 100% !important;
}
.d-flex.align-items-center.justify-content-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.copy-icon {
  font-size: 20px;
  color: #333;
  cursor: pointer;
}
.form-label {
  font-weight: bold;
  font-size: 16px;
  color: #333;
}
.jodit-add-new-line:after{
  display: none !important;
}