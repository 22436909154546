//
// react-table
//

.ReactTable {
    .rt-thead{
        background-color: rgb(245, 248, 250);
        border-radius: 6px;
        &.-header{
            padding-top: 0 !important;
            box-shadow: none !important;
        }
        .rt-th{
            padding: 13px 13px 7px 13px !important;
        }
        .rt-resizable-header-content{
            letter-spacing:normal;
            text-transform:capitalize;
            font-size: 13px;
        }
    }
}

.pagination{
    .from-start{
        i{
            &.previous{
                &.first{
                    padding-left: 5px;
                    position: relative;
                    z-index: 0;
                    left: -2.5px;
                }
            }
        }
    }
    .to-end{
        i{
            &.next{
                &.first{
                    padding-left: 5px;
                    position: relative;
                    z-index: 0;
                    left: -2px;
                }
            }
        }
    }
}